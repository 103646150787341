@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-page-default
  h1
    font-size: 2em
  h2
    font-size: 1.4em
